import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

function AuthGuard({ children }) {
  const userRole = useSelector(state => {
    const { admin } = state.session.user
    if (admin && admin.permissions && admin.permissions.length)
      return admin.permissions[admin.permissions.length - 1]
    return ''
  })

  if (!userRole) {
    return <Redirect to="/403" />
  }

  return <>{children}</>
}

AuthGuard.propTypes = {
  children: PropTypes.any
}

export default AuthGuard
