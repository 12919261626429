import axios from 'src/utils/axios'

class RequestsService {
  getList = (params = {}) => {
    return axios.get('/requests', { params }).then(res => res.data)
  }

  getByUniqueId = id => {
    return axios.get(`/requests/${id}`).then(res => res.data)
  }

  getCount = () => {
    return axios.get('/requests/count').then(res => res.data)
  }

  updateDecision = (body = {}) => {
    return axios.post('/requests', body).then(res => res.data)
  }

  update = (id, data, params = {}) => {
    return axios
      .patch(`/requests/${id}`, data, { params })
      .then(res => res.data)
  }
}

export default new RequestsService()
