import axios from 'src/utils/axios'

class PublicationsService {
  getList = (params = {}) =>
    axios.get('/publications', { params }).then(res => res.data)

  getById = id => axios.get(`/publications/${id}`).then(res => res.data)

  update = (id, data) =>
    axios.patch(`/publications/${id}`, data).then(res => res.data)
}

export default new PublicationsService()
