const getParams = (params = {}) => {
  if (Object.values(params).some(param => param)) {
    // prettier-ignore
    const p = Object.entries(params)
      .filter(([, v]) => v)
      .map(([k, v]) => Array.isArray(v) ? v.map(w => `${k}[]=${w}`).join('&') : `${k}=${v}`)
      .join('&')

    return `?${p}`
  }

  return ''
}

export default getParams
