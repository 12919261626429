import axios from 'src/utils/axios'

export default class FilesService {
  loadDoc = data => axios.post('/files/document', data).then(res => res.data)

  loadDocs = data => axios.post('/files/documents', data).then(res => res.data)

  loadImg = data => axios.post('/files/image', data).then(res => res.data)

  loadImgs = data => axios.post('/files/images', data).then(res => res.data)

  loadVid = data => axios.post('/files/video', data).then(res => res.data)

  loadVids = data => axios.post('/files/videos', data).then(res => res.data)

  getFile = (id, params = {}) =>
    axios.post(`/files/${id}/`, { ...params }).then(res => res.data)
}
