import axios from 'src/utils/axios'

class ServicesService {
  getList = (params = {}) => {
    return axios.get('/services', { params }).then(res => res.data)
  }

  getById = id => {
    return axios.get(`/services/${id}`).then(res => res.data)
  }

  getByUniqueId = id => {
    return axios
      .get('/services', { params: { uniqueId: id } })
      .then(res => res.data.items[0])
  }

  getReviewsById = (id, params = {}) => {
    return axios
      .get(`/services/${id}/reviews`, { params })
      .then(res => res.data)
  }

  removeReview = (idService, idReview) => {
    return axios.delete(`/services/${idService}/reviews/${idReview}`)
  }
}

export default new ServicesService()
