const getAudience = (category, goal, adGoal, classifiers) => {
  switch (category) {
    case 'FINANCE': {
      return goal?.name || '-'
    }
    case 'FINANCE2': {
      return adGoal?.name || '-'
    }
    case 'PRODUCT': {
      const audience = []
      classifiers.forEach(({ name, parent }) => {
        if (parent) {
          if (!audience.includes(parent.name)) audience.push(parent.name)
        } else if (!audience.includes(name)) audience.push(name)
      })
      return audience.join(', ') || '-'
    }
    default:
      return '-'
  }
}

export default getAudience
