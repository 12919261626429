/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { useLocation, matchPath } from 'react-router'
import { useSelector } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { baseUrl } from 'src/utils'
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core'
import {
  PieChart as PieChartIcon,
  Users as UsersIcon,
  Briefcase as BriefcaseIcon,
  Copy as CopyIcon,
  PlusSquare as PlusSquareIcon,
  UserCheck as UserCheckIcon,
  CreditCard as CreditCardIcon,
  MessageSquare as MessageSquareIcon,
  Hash as HashIcon,
  Settings as SettingsIcon,
  DollarSign as AdsIcon,
  BarChart as BarChartIcon,
  AlertTriangle as AlertTriangleIcon,
  GitPullRequest as GitPullRequestIcon,
  GitMerge as GitMergeIcon,
  UserPlus as UserPlusIcon,
  DollarSign as DollarSignIcon,
  Calendar as CalendarIcon
} from 'react-feather'
import NavItem from './NavItem'

const navConfig = [
  {
    items: [
      {
        title: 'Дешборд',
        icon: PieChartIcon,
        href: '/dashboard'
      },
      {
        title: 'Все пользователи',
        icon: UsersIcon,
        href: '/customers'
      },
      {
        title: 'Управление рекламой',
        icon: AdsIcon,
        items: [
          {
            title: 'Управление баннерами',
            href: '/banners'
          },
          {
            title: 'Реклама в чате',
            href: '/catalogs/ads-chat'
          },
          {
            title: 'Баннерная реклама',
            href: '/catalogs/ads-banner'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Каталоги',
    items: [
      {
        title: 'Новые запросы',
        icon: PlusSquareIcon,
        href: '/requests'
      },
      {
        title: 'Заявки',
        icon: CopyIcon,
        items: [
          {
            title: 'Юридические лица',
            href: '/catalogs/entities'
          },
          {
            title: 'Физические лица',
            href: '/catalogs/individuals'
          }
        ]
      },
      {
        title: 'Консультации',
        icon: UserCheckIcon,
        href: '/catalogs/services'
      }
    ]
  },
  {
    subheader: 'Лента',
    items: [
      {
        title: 'Публикации',
        icon: CreditCardIcon,
        href: '/catalogs/publications'
      },
      {
        title: 'Рубрики',
        icon: HashIcon,
        href: '/catalogs/topics'
      },
      {
        title: 'Комментарии',
        icon: MessageSquareIcon,
        href: '/catalogs/comments'
      }
    ]
  },
  {
    subheader: 'Система',
    items: [
      {
        title: 'События',
        icon: CalendarIcon,
        href: '/system/events'
      },
      {
        title: (
          <>
            Ньютон С<sup>3</sup>
          </>
        ),
        icon: CalendarIcon,
        href: '/system/newton-s3'
      },
      {
        title: 'Рейтинг',
        icon: BarChartIcon,
        href: '/system/rating'
      },
      {
        title: 'Жалобы',
        icon: AlertTriangleIcon,
        href: '/system/reports'
      },
      {
        title: 'Обратная связь',
        icon: UserPlusIcon,
        href: '/system/contacts'
      },
      {
        title: 'Классификаторы',
        icon: GitPullRequestIcon,
        href: '/system/classifiers'
      },
      {
        title: 'Рекламные виды',
        icon: GitMergeIcon,
        href: '/system/adgoals'
      },
      {
        title: 'Цены',
        icon: DollarSignIcon,
        href: '/system/prices'
      },
      {
        title: 'Список банков',
        icon: BriefcaseIcon,
        href: '/system/banks'
      },
      {
        title: 'Настройки',
        icon: SettingsIcon,
        href: '/system/settings'
      }
    ]
  }
]

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  )
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    })

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        open={Boolean(open)}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.items,
          pathname
        })}
      </NavItem>
    )
  } else {
    acc.push(
      <NavItem
        href={item.href}
        depth={depth}
        icon={item.icon}
        key={key}
        title={item.title}
        info={item.info}
      />
    )
  }

  return acc
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}))

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles()
  const location = useLocation()
  const user = useSelector(state => state.session.user)

  const { profile } = user

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
    // eslint-disable-next-line
  }, [location.pathname])

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to={`/customers/${user?._id}/info`}>
              <Avatar
                className={classes.avatar}
                src={
                  profile && profile.avatar
                    ? `${baseUrl}/${profile.avatar.minImage}`
                    : ''
                }
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to={`/customers/${user?._id}/info`}
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${profile.firstName || ''} ${profile.lastName || ''}`.trim()}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {profile.position || ''}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config, i) => (
            <List
              key={i.toString()}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
}

export default NavBar
