import axios from 'src/utils/axios'

class LoansService {
  getList = (params = {}) => {
    return axios.get('/loans', { params }).then(res => res.data)
  }

  getById = id => {
    return axios.get(`/loans/${id}`).then(res => res.data)
  }

  getByUniqueId = id => {
    return axios
      .get('/loans', { params: { uniqueId: id } })
      .then(res => res.data.items[0])
  }

  update = (id, data) => {
    return axios.patch(`/loans/${id}`, { ...data })
  }
}

export default new LoansService()
