import axios from 'src/utils/axios'

export default class AdsService {
  getList = (params = {}) =>
    axios.get('/notads', { params }).then(res => res.data)

  getRequestsList = (params = {}) => {
    return axios.get('/notads/requests', { params })
  }

  publish = data => axios.post('/notads', data).then(res => res.data)

  getByUniqueId = id => axios.get(`/notads/${id}`).then(res => res.data)

  update = (id, data) => axios.patch(`/notads/${id}`, { ...data })

  delete = id => axios.delete(`/notads/${id}`).then(res => res.data)

  deletePromocode = id =>
    axios.delete(`/notads/${id}/promocode`).then(res => res.data)

  createBanner = (id, data) => axios.post(`/notads/${id}/banners`, { ...data })

  updateBanner = (id, bid, data) =>
    axios.patch(`/notads/${id}/banners/${bid}`, { ...data })

  deleteBanner = (id, bid) =>
    axios.delete(`/notads/${id}/banners/${bid}`).then(res => res.data)
}
