import axios from 'src/utils/axios'

export default class bannersService {
  getList = (params = {}) =>
    axios.get('/banners', { params }).then(res => res.data)

  publish = data => axios.post('/banners', data).then(res => res.data)

  getByUniqueId = id => axios.get(`/banners/${id}`).then(res => res.data)

  update = (id, data) => axios.patch(`/banners/${id}`, { ...data })

  delete = id => axios.delete(`/banners/${id}`).then(res => res.data)
}
