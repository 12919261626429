import axios from 'src/utils/axios'

class CountService {
  getEventGoalsCount = () => {
    return axios.get('/eventgoals/count').then(res => res.data)
  }

  getAdminCounts = () => {
    return axios.get('/counts/admin').then(res => res.data)
  }

  getUsers = (params = {}) => {
    return axios.get('/users/count', { params }).then(res => res.data)
  }

  getLoans = (params = {}) => {
    return axios.get('/loans/count', { params }).then(res => res.data)
  }

  getServices = (params = {}) => {
    return axios.get('/services/count', { params }).then(res => res.data)
  }

  getConversations = (params = {}) => {
    return axios.get('/conversations/count', { params }).then(res => res.data)
  }

  getMessages = (params = {}) => {
    return axios.get('/messages/count', { params }).then(res => res.data)
  }

  getPermissions = (params = {}) => {
    return axios.get('/permissions/count', { params }).then(res => res.data)
  }

  getPublications = (params = {}) => {
    return axios.get('/publications/count', { params }).then(res => res.data)
  }

  getTags = (params = {}) => {
    return axios.get('/tags/count', { params }).then(res => res.data)
  }

  getComments = (params = {}) => {
    return axios.get('/comments/count', { params }).then(res => res.data)
  }

  getContacts = (params = {}) => {
    return axios.get('/contacts/count', { params }).then(res => res.data)
  }

  getShare = (params = {}) => {
    return axios
      .get('/records/count?event=SHARE_TO_SOCIAL', { params })
      .then(res => res.data)
  }
}

export const getAllCounts = async (params = {}) => {
  const countService = new CountService()

  const data = await Promise.all([
    countService
      .getEventGoalsCount(params)
      .then(res => ({ eventgoals: res }))
      .catch(() => ({ eventgoals: { common: 'Ошибка' } })),
    countService
      .getUsers(params)
      .then(res => ({ users: res }))
      .catch(() => ({ users: { common: 'Ошибка' } })),
    countService
      .getLoans(params)
      .then(res => ({ loans: res }))
      .catch(() => ({ loans: { common: 'Ошибка' } })),
    countService
      .getServices(params)
      .then(res => ({ services: res }))
      .catch(() => ({ services: { common: 'Ошибка' } })),
    countService
      .getConversations(params)
      .then(res => ({ conversations: res }))
      .catch(() => ({ conversations: { common: 'Ошибка' } })),
    countService
      .getMessages(params)
      .then(res => ({ messages: res }))
      .catch(() => ({ messages: { common: 'Ошибка' } })),
    countService
      .getPermissions(params)
      .then(res => ({ permissions: res }))
      .catch(() => ({ permissions: { common: 'Ошибка' } })),
    countService
      .getPublications(params)
      .then(res => ({ publications: res }))
      .catch(() => ({ publications: { common: 'Ошибка' } })),
    countService
      .getTags(params)
      .then(res => ({ tags: res }))
      .catch(() => ({ tags: { common: 'Ошибка' } })),
    countService
      .getComments(params)
      .then(res => ({ comments: res }))
      .catch(() => ({ comments: { common: 'Ошибка' } })),
    countService
      .getContacts(params)
      .then(res => ({ contacts: res }))
      .catch(() => ({ contacts: { common: 'Ошибка' } })),
    countService
      .getShare(params)
      .then(res => ({ share: res }))
      .catch(() => ({ share: { common: 'Ошибка' } }))
  ])

  return Object.assign({}, ...data)
}

export default new CountService()
