import axios from 'src/utils/axios'
import { CookieService } from './cookieService'

class AuthService extends CookieService {
  signin = ({ phone, password }) => {
    return axios.post('/auth/signin', { phone, password }).then(res => {
      this.setTokens(res.data)
      return res.data
    })
  }

  signup = ({ phone, password }) => {
    return axios.post('/auth/signup', { phone, password }).then(res => {
      this.setTokens(res.data)
      return res.data
    })
  }

  logout = () => {
    this.clearTokens()
  }

  confirm = password => {
    return axios.post('/auth/confirm', { password })
  }

  acceptCode = (phone, restore = false) => {
    return axios.post('/users/accept-code', { phone, restore })
  }

  acceptPhone = (phone, code, restore = false) => {
    return axios.post('/users/accept-phone', { phone, code, restore })
  }
}

export default new AuthService()
