import axios from 'src/utils/axios'

class TagsService {
  getList = (params = {}) => {
    return axios.get('/tags', { params }).then(res => res.data)
  }

  getById = id => {
    return axios.get(`/tags/${id}`).then(res => res.data)
  }
}

export default new TagsService()
