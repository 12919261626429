const getUpdatedData = ({ keys, obj1, obj2 }) => {
  const objToStr = obj =>
    Object.entries(obj)
      .filter(([key]) => keys.includes(key))
      .map(([key, value]) => JSON.stringify({ [key]: value }))

  const strObj1 = objToStr(obj1)
  const strObj2 = objToStr(obj2)

  const objArr = strObj1
    .filter(objItem => !strObj2.includes(objItem))
    .map(objItem => JSON.parse(objItem))

  return Object.assign({}, ...objArr)
}

export default getUpdatedData
