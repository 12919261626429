export const SOCKET_INIT = 'SOCKET_INIT'
export const SOCKET_DESTROY = 'SOCKET_DESTROY'
export const USERS_CONNECTED = 'USERS_CONNECTED'

export const initConnection = () => dispatch => dispatch({ type: SOCKET_INIT })

export const destroyConnection = () => dispatch =>
  dispatch({ type: SOCKET_DESTROY })

export const connectUsers = users => dispatch =>
  dispatch({ type: USERS_CONNECTED, payload: users })
