import axios from 'src/utils/axios'

class UsersService {
  getCurrent = () => {
    return axios.get('/users/current').then(res => res.data)
  }

  getList = (params = {}) => {
    return axios.get('/users', { params }).then(res => res.data)
  }

  getById = id => {
    return axios.get(`/users/${id}`).then(res => res.data)
  }

  update = data => {
    return axios.patch(`/users/current`, data)
  }

  updateRole = (id, role) => {
    return axios.patch(`/users/${id}/admin`, { role })
  }

  updatePassword = password => {
    return axios.post('/users/update-password', { password })
  }

  updateBlocked = id => {
    return axios.patch(`/users/${id}/blocked`, { id })
  }

  updateModerated = id => {
    return axios.patch(`/users/${id}/moderated`, { id })
  }

  updateSubscription = (id, subscription) => {
    return axios.patch(`/users/${id}/update-subscription`, subscription)
  }

  clearUser = id => {
    return axios.delete(`/users/${id}`)
  }
}

export default new UsersService()
