import React, { useRef, useState } from 'react'
// import { Link as RouterLink } from 'react-router-dom'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { authService } from 'src/services'
import { logout } from 'src/actions'
import { baseUrl } from 'src/utils'
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  avatar: {
    height: 32,
    width: 32
  },
  popover: {
    width: 200
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 16
  }
}))

function Account() {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const ref = useRef(null)
  const [open, setOpen] = useState(false)
  const profile = useSelector(state => state.session.user.profile)

  const handleLogout = async () => {
    try {
      await authService.logout()
      history.push('/auth/login')
      dispatch(logout())
    } catch (err) {
      enqueueSnackbar('Ошибка!', {
        variant: 'error'
      })
    } finally {
      setOpen(false)
    }
  }

  return (
    <>
      <Box
        ref={ref}
        component={ButtonBase}
        className={classes.profile}
        onClick={() => setOpen(true)}
      >
        <Avatar
          className={classes.avatar}
          src={
            profile && profile.avatar
              ? `${baseUrl}/${profile.avatar.minImage}`
              : ''
          }
        />
        {profile && (profile.firstName || profile.lastName) && (
          <Hidden smDown>
            <Box padding="0 8px">
              <Typography variant="h6" color="inherit">
                {`${profile.firstName || ''} ${profile.lastName || ''}`.trim()}
              </Typography>
            </Box>
          </Hidden>
        )}
      </Box>
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        {/* <MenuItem component={RouterLink} to="/dashboard">
          Профиль
        </MenuItem> */}
        {/* <MenuItem component={RouterLink} to="/dashboard">
          Настройки
        </MenuItem> */}
        <MenuItem onClick={handleLogout}>Выйти</MenuItem>
      </Menu>
    </>
  )
}

export default Account
