import { SESSION_LOGIN, SESSION_LOGOUT, CONTACT_VIEWED } from 'src/actions'

const initialState = {
  loggedIn: false,
  user: {},
  requestsCount: {}
}

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_LOGIN: {
      return {
        ...state,
        ...action.payload,
        loggedIn: true
      }
    }

    case SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        user: {
          role: 'GUEST'
        }
      }
    }

    case CONTACT_VIEWED: {
      const contacts = state?.requestsCount?.contacts

      const requestsCount =
        Object.keys(state?.requestsCount)?.length && contacts
          ? { ...state.requestsCount, contacts: contacts - 1 }
          : state.requestsCount

      return {
        ...state,
        requestsCount
      }
    }

    default: {
      return state
    }
  }
}

export default sessionReducer
