import axios from 'src/utils/axios'

export default class ClassifiersService {
  getList = (params = {}) => {
    return axios.get('/classifiers', { params }).then(res => res.data)
  }

  getById = id => {
    return axios.get(`/classifiers/${id}`).then(res => res.data)
  }

  create = data => {
    return axios.post('/classifiers', data).then(res => res.data)
  }

  update = (id, data) => {
    return axios.patch(`/classifiers/${id}`, data).then(res => res.data)
  }
}
