import React from 'react'
import { PropTypes } from 'prop-types'
import {
  PhotoCamera as PhotoCameraIcon,
  TextFields as TextFieldsIcon,
  Videocam as VideocamIcon,
  Link as LinkIcon,
  Book as BookIcon,
  HelpOutline as HelpOutlineIcon,
  HowToVote as HowToVoteIcon
} from '@material-ui/icons'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  GridList,
  GridListTile
} from '@material-ui/core'
import Label from 'src/components/Label'
import ModalImage from 'react-modal-image'
import ReactPlayer from 'react-player'
import ReactHtmlParser from 'react-html-parser'

const types = {
  QUESTION: { color: 'secondary', title: 'Вопрос' },
  POST: { color: 'secondary', title: 'Пост' },
  TEXT: { color: 'secondary', title: 'Текст' },
  LINK: { color: 'secondary', title: 'Ссылка' },
  VIDEO: { color: 'secondary', title: 'Видео' },
  IMAGE: { color: 'secondary', title: 'Изображение' },
  VOTE: { color: 'secondary', title: 'Опрос' }
}

const labelType = (status, title?) => {
  if (status && title) return { color: types[status].color, title }
  if (!status) return types['DEFAULT']
  return types[status]
}

export const getType = (status, title?) =>
  status && (
    <Label color={labelType(status, title).color}>
      {labelType(status, title).title}
    </Label>
  )

export const getTextType = title => types[title].title

export const getBlocks = (block, oldVotes) => {
  switch (block.type) {
    case 'TEXT':
      return <div>{ReactHtmlParser(block.content?.text)}</div>
    case 'LINK':
      return (
        <a
          href={block.content?.url || null}
          target="_blank"
          rel="noopener noreferrer"
        >
          {block.content?.url}
        </a>
      )
    case 'IMAGE':
      return (
        <div>
          <GridList>
            {block?.content?.items.map(item => (
              <GridListTile key={item.src}>
                {item.type?.includes('video') ? (
                  <ReactPlayer
                    url={item.src || ''}
                    width="100%"
                    height="100%"
                    controls
                  />
                ) : (
                  <ModalImage small={item.src} large={item.src} alt="" />
                )}
              </GridListTile>
            ))}
          </GridList>
        </div>
      )
    case 'VIDEO':
      return (
        <ReactPlayer
          url={block.content?.url || ''}
          width="100%"
          height="100%"
          controls
        />
      )
    case 'VOTE': {
      const votes = oldVotes.length > 0 ? oldVotes : block.content.votes
      const variables =
        block.content?.variables?.length > 0
          ? block.content.variables
          : ['Да', 'Нет']
      const votesCount = Array(variables.length).fill(0)
      votes.forEach(vote => votesCount[vote.decision]++)
      const labels = ['Реакция', 'Количество']
      return (
        <div>
          <Typography>{`Всего голосов: ${votesCount.reduce(
            (totalCount, count) => totalCount + count
          )}`}</Typography>
          <Table>
            <TableHead>
              <TableRow>
                {labels.map(label => (
                  <TableCell style={{ wordBreak: 'keep-all' }} key={label}>
                    {label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {variables.map((variable, index) => {
                return (
                  <TableRow key={variable}>
                    <TableCell>
                      <Label color="secondary">{variable}</Label>
                    </TableCell>
                    <TableCell>{votesCount[index]}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      )
    }
    default:
      return null
  }
}

export const renderTopics = topics => {
  return topics.map(({ name }) => (
    <div style={{ marginBottom: 4 }}>
      <Label color="success" key={name}>
        {name}
      </Label>
    </div>
  ))
}

export const PublicationIcon = ({ block }) => {
  switch (block) {
    case 'TEXT':
      return <TextFieldsIcon color="disabled" />
    case 'QUESTION':
      return <HelpOutlineIcon color="disabled" />
    case 'POST':
      return <BookIcon color="disabled" />
    case 'LINK':
      return <LinkIcon color="disabled" />
    case 'VIDEO':
      return <VideocamIcon color="disabled" />
    case 'IMAGE':
      return <PhotoCameraIcon color="disabled" />
    case 'VOTE':
      return <HowToVoteIcon color="disabled" />
    default:
      return <TextFieldsIcon color="disabled" />
  }
}

PublicationIcon.propTypes = {
  block: PropTypes.string.isRequired
}

export default labelType
