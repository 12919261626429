export const SESSION_LOGIN = 'SESSION_LOGIN'
export const SESSION_LOGOUT = 'SESSION_LOGOUT'
export const CONTACT_VIEWED = 'CONTACT_VIEWED'

export const login = payload => dispatch =>
  dispatch({
    type: SESSION_LOGIN,
    payload
  })

export const logout = () => dispatch =>
  dispatch({
    type: SESSION_LOGOUT
  })

export const setContactViewed = () => dispatch =>
  dispatch({
    type: CONTACT_VIEWED
  })
