// import { PriceService } from 'src/services'

// const priceApi = new PriceService()

const getClassifiersPrice = classifiers => {
  const res = []
  const priceOpenForm = []

  if (Array.isArray(classifiers)) {
    classifiers.forEach(priceItem => {
      let parentIndex = priceItem?.parent
        ? priceOpenForm.findIndex(
            classifier => classifier._id === priceItem?.parent?._id
          )
        : priceOpenForm.findIndex(
            classifier => classifier._id === priceItem?._id
          )

      if (parentIndex === -1) {
        const parentItem = priceItem?.parent ? priceItem.parent : priceItem
        priceOpenForm.push({
          _id: parentItem?._id,
          name: parentItem?.name,
          children: []
        })
        parentIndex = priceOpenForm.length - 1
      }
      if (priceItem?.parent)
        priceOpenForm[parentIndex].children.push({
          _id: priceItem._id,
          name: priceItem.name
        })
    })

    // priceOpenForm.forEach(async parent => {
    //   const parentPrice = await priceApi.getOneClassifier(parent._id)
    //   res.push({ label: parent.name, value: parentPrice?.price })
    //   if (parent.children) {
    //     parent.children.forEach(async child => {
    //       const childPrice = await priceApi.getOneClassifier(child._id)
    //       res.push({ label: child.name, value: childPrice?.price })
    //     })
    //   }
    // })

    priceOpenForm.forEach(parent => {
      res.push({ label: parent.name })
      if (parent.children) {
        parent.children.forEach(child => {
          res.push({ label: child.name })
        })
      }
    })
  }

  return res
}

export default getClassifiersPrice
