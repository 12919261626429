/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import DashboardLayout from 'src/layouts/DashboardLayout'
// import DocsLayout from 'src/layouts/DocsLayout'
// import MainLayout from 'src/layouts/MainLayout'
import LoadingScreen from 'src/components/LoadingScreen'
import AuthGuard from 'src/components/AuthGuard'
import GuestGuard from 'src/components/GuestGuard'

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: '/403',
    exact: true,
    component: lazy(() => import('src/views/pages/Error403View'))
  },

  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    path: '/auth',
    guard: GuestGuard,
    routes: [
      {
        exact: true,
        path: '/auth/login',
        component: lazy(() => import('src/views/auth/LoginView'))
      },
      // {
      //   exact: true,
      //   path: '/auth/register',
      //   component: lazy(() => import('src/views/auth/RegisterView'))
      // },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/catalogs',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/catalogs/:tab',
        component: lazy(() => import('src/views/catalogs/CatalogListView'))
      },
      {
        path: '/catalogs/:tab/:id',
        exact: true,
        component: lazy(() => import('src/views/catalogs/CatalogDetailsView'))
      },
      {
        component: () => <Redirect to="/catalogs/entities" />
      }
    ]
  },
  {
    path: '/requests',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/requests/:tab',
        component: lazy(() => import('src/views/requests'))
      },
      {
        component: () => <Redirect to="/requests/entities" />
      }
    ]
  },
  {
    path: '/customers',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/customers',
        component: lazy(() => import('src/views/customers/CustomerListView'))
      },
      {
        exact: true,
        path: '/customers/:id',
        component: lazy(() => import('src/views/customers/CustomerDetailsView'))
      },
      {
        path: '/customers/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/customers/CustomerDetailsView'))
      },
      {
        component: () => <Redirect to="/customers" />
      }
    ]
  },
  {
    path: '/system',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/system/:section',
        component: lazy(() => import('src/views/system/SystemView'))
      },
      {
        exact: true,
        path: '/system/reports/:id',
        component: lazy(() => import('src/views/reports/ReportDetailView'))
      },
      {
        exact: true,
        path: '/system/reports/:id',
        component: lazy(() => import('src/views/reports/ReportDetailView'))
      }
    ]
  },
  {
    path: '/banners',
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/bannerControl/BannerListView'))
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        path: '/dashboard',
        exact: true,
        component: lazy(() => import('src/views/dashboard/DashboardView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
]

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment
          const Layout = route.layout || Fragment
          const Component = route.component

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          )
        })}
      </Switch>
    </Suspense>
  ) : null

function Routes() {
  return renderRoutes(routesConfig)
}

export default Routes
