import axios from 'src/utils/axios'

class CatalogService {
  catalog = 'loans'

  constructor(catalog = 'loans') {
    this.catalog = catalog
  }

  getList(params = {}) {
    return axios.get(`/${this.catalog}`, { params })
  }

  getById(id, params = {}) {
    return axios.get(`/${this.catalog}/${id}`, { params })
  }

  getRequestsList(params = {}) {
    return axios.get(`/${this.catalog}/requests`, { params })
  }

  getRequestsByItemId(id, params = {}) {
    return axios.get(`/${this.catalog}/${id}/requests`, { params })
  }

  createRequestForItem(id, data, params = {}) {
    return axios.post(`/${this.catalog}/${id}/requests`, data, { params })
  }
}

export default CatalogService
