import axios from 'src/utils/axios'

export default class AdGoalsService {
  getList = (params = {}) => {
    return axios.get('/adgoals', { params }).then(res => res.data)
  }

  getById = id => {
    return axios.get(`/adgoals/${id}`).then(res => res.data)
  }

  create = data => {
    return axios.post('/adgoals', data).then(res => res.data)
  }

  update = (id, data) => {
    return axios.patch(`/adgoals/${id}`, data)
  }

  delete = id => {
    return axios.delete(`/adgoals/${id}`)
  }
}
