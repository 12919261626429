import React from 'react'
import Label from 'src/components/Label'

const data = {
  MODERATION: { color: 'secondary', title: 'На модерации' },
  PUBLISHED: { color: 'success', title: 'Опубликовано' },
  UNPUBLISHED: { color: 'warning', title: 'Снято с публикации' },
  DRAFT: { color: 'warning', title: 'Черновик' },
  ARCHIVED: { color: 'warning', title: 'Архив' },
  REJECTED: { color: 'error', title: 'Отклонено' },
  REMOVED: { color: 'error', title: 'Удалено' },
  DEFAULT: { color: '', title: 'Нет статуса' },

  SUCCESS: { color: 'success' },
  WARNING: { color: 'warning' },
  ERROR: { color: 'error' }
}

const labelStatus = (status, title?) => {
  if (status && title) return { color: data[status].color, title }
  if (!status) return data['DEFAULT']
  return data[status]
}

export const getStatus = (status, title?) => (
  <Label color={labelStatus(status, title).color}>
    {labelStatus(status, title).title}
  </Label>
)

export default labelStatus
