import axios from 'src/utils/axios'

class TopicsService {
  getList = (params = {}) =>
    axios.get('/topics', { params }).then(res => res.data)

  getRequests = (params = {}) =>
    axios.get('/topics/requests', { params }).then(res => res.data)

  getById = id => axios.get(`/topics/${id}`).then(res => res.data)

  create = data => axios.post('/topics', data).then(res => res.data)

  update = (id, data) =>
    axios.patch(`/topics/${id}`, data).then(res => res.data)

  delete = id => axios.delete(`/topics/${id}`).then(res => res.data)

  updateRequest = (id, data) =>
    axios.post(`/topics/${id}/requests`, data).then(res => res.data)
}

export default new TopicsService()
