import { combineReducers } from 'redux'
import sessionReducer from './sessionReducer'
import socketReducer from './socketReducer'

const rootReducer = combineReducers({
  session: sessionReducer,
  socket: socketReducer
})

export default rootReducer
