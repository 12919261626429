import axios from 'src/utils/axios'

export default class BanksService {
  getList = (params = {}) => {
    return axios.get('/prices', { params }).then(res => res.data)
  }

  getById = id => {
    return axios.get('/prices', { id }).then(res => res.data)
  }

  updatePrice = (id, data) => {
    axios.patch(`/prices/${id}`, data).then(res => res.data)
  }
}
