import React, { useState, useRef } from 'react'
import { capitalCase } from 'change-case'
import { Settings as SettingsIcon } from 'react-feather'
import useSettings from 'src/hooks/useSettings'
import { THEMES } from 'src/constants'
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Popover,
  SvgIcon,
  Switch,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  popover: {
    width: 320,
    padding: theme.spacing(2)
  }
}))

function Settings() {
  const classes = useStyles()
  const ref = useRef(null)
  const { settings, saveSettings } = useSettings()
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState({
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  })

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value
    })
  }

  return (
    <>
      <Tooltip title="Настройки">
        <IconButton ref={ref} color="inherit" onClick={() => setOpen(true)}>
          <SvgIcon fontSize="small">
            <SettingsIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <Typography variant="h4" color="textPrimary">
          Настройки
        </Typography>
        <Box mt={2} px={1}>
          <FormControlLabel
            label="Адаптивные шрифты"
            control={
              <Switch
                checked={values.responsiveFontSizes}
                edge="start"
                name="direction"
                onChange={event =>
                  handleChange('responsiveFontSizes', event.target.checked)
                }
              />
            }
          />
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            select
            label="Тема"
            name="theme"
            size="small"
            variant="outlined"
            value={values.theme}
            onChange={event => handleChange('theme', event.target.value)}
            SelectProps={{ native: true }}
          >
            {Object.keys(THEMES).map(theme => (
              <option key={theme} value={theme}>
                {capitalCase(theme)}
              </option>
            ))}
          </TextField>
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => {
              saveSettings(values)
              setOpen(false)
            }}
          >
            Сохранить
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default Settings
