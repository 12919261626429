import axios from 'src/utils/axios'

class CitiesService {
  getList = search => {
    return axios
      .get('/cities', { params: { search, limit: 20 } })
      .then(res => res.data)
  }

  getById = id => {
    return axios.get(`/cities/${id}`, {}).then(res => res.data)
  }
}

export default new CitiesService()
