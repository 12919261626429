import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { usersService, countService } from 'src/services'
import {
  login,
  initConnection,
  destroyConnection,
  connectUsers
} from 'src/actions'
import LoadingScreen from 'src/components/LoadingScreen'

function Auth({ children }) {
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const dispatch = useDispatch()

  const { session, socket } = useSelector(state => ({
    session: state.session,
    socket: state.socket.socket
  }))

  useEffect(() => {
    const auth = async () => {
      try {
        const user = await usersService.getCurrent()
        dispatch(login({ user }))

        if (history.location.pathname.includes('auth'))
          history.push('/dashboard')
      } catch (err) {
        history.push('/auth/login')
      } finally {
        setTimeout(() => setLoading(false), 200)
      }
    }

    if (!session.loggedIn || !session.user) auth()
  }, [history, dispatch, session.loggedIn, session.user])

  useEffect(() => {
    let mounted = true
    if (mounted) dispatch(initConnection())
    return () => {
      dispatch(destroyConnection())
      mounted = false
    }
  }, [dispatch])

  useEffect(() => {
    const fetchRequestsCount = async () => {
      try {
        const requestsCount = await countService.getAdminCounts()
        dispatch(login({ requestsCount }))
      } catch (err) {
        dispatch(login({ requestsCount: {} }))
      }
    }

    fetchRequestsCount()

    const interval = setInterval(() => {
      fetchRequestsCount()
    }, 600000)

    return () => clearInterval(interval)
  }, [dispatch])

  useEffect(() => {
    if (socket) {
      socket.on('connected users', message => dispatch(connectUsers(message)))

      socket.on('reconnect', attempt => {
        console.log('from reconnect', attempt)
      })

      socket.on('disconnect', reason => {
        console.log('from disconnect', reason)
        if (reason === 'io server disconnect') socket.connect()
      })
    }
  }, [dispatch, socket])

  if (loading) {
    return <LoadingScreen />
  }

  return <>{children}</>
}

Auth.propTypes = {
  children: PropTypes.any
}

export default Auth
