import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { moment } from 'src/utils'
import StatusBullet from 'src/components/StatusBullet'
import {
  Box,
  Drawer,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
  Avatar,
  Badge,
  List,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles
} from '@material-ui/core'
import { Users as UsersIcon, X as XIcon } from 'react-feather'

const groups = [
  {
    id: 'admins',
    name: 'Администраторы',
    count: 0
  },
  {
    id: 'users',
    name: 'Пользователи',
    count: 0
  }
]

const useStyles = makeStyles(theme => ({
  drawer: {
    maxWidth: 320,
    width: '100%'
  },
  listText: {
    display: 'block',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  lastActivity: {
    whiteSpace: 'nowrap'
  }
}))

function Users() {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { connectedUsers } = useSelector(state => state.socket)
  const [users] = useState([])

  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     const res = await usersService.getList({
  //       id: connectedUsers,
  //       limit: 100000
  //     })

  //     if (res.items && res.items.length) {
  //       setUsers(
  //         res.items.map(({ _id, login, profile, admin }) => ({
  //           id: _id,
  //           name: `${profile.firstName} ${profile.lastName}`,
  //           avatar: profile.avatar
  //             ? `${baseUrl}/${profile.avatar.minImage}`
  //             : '',
  //           active: true,
  //           lastActivity: login.lastLogout,
  //           group:
  //             admin && admin.permissions && admin.permissions.length
  //               ? 'admins'
  //               : 'users'
  //         }))
  //       )
  //     }
  //   }

  //   if (open && connectedUsers.length) fetchUsers()

  //   return () => {
  //     setUsers([])
  //   }
  // }, [open, connectedUsers])

  return (
    <>
      <Tooltip title="Пользователи">
        <IconButton color="inherit" onClick={() => setOpen(true)}>
          <Badge color="error" badgeContent={connectedUsers.length}>
            <SvgIcon fontSize="small">
              <UsersIcon />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        variant="temporary"
        open={open}
        onClose={() => setOpen(false)}
        classes={{ paper: classes.drawer }}
        ModalProps={{ BackdropProps: { invisible: true } }}
      >
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box p={3}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4" color="textPrimary">
                Пользователи
              </Typography>
              <IconButton onClick={() => setOpen(false)}>
                <SvgIcon fontSize="small">
                  <XIcon />
                </SvgIcon>
              </IconButton>
            </Box>
            <Box my={1}>
              {groups.map(group => (
                <List
                  key={group.id}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      <Box mr={2} display="inline-block">
                        {group.name}
                      </Box>
                      {/* <Badge
                        showZero
                        color="secondary"
                        badgeContent={
                          users.filter(item => item.group === group.id).length
                        }
                      /> */}
                    </ListSubheader>
                  }
                >
                  {users
                    .filter(connection => group.id === connection.group)
                    .map(connection => (
                      <ListItem disableGutters key={connection.id}>
                        <ListItemAvatar>
                          <Avatar
                            src={connection.avatar}
                            to={`/customers/${connection.id}/info`}
                            component={RouterLink}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              to={`/customers/${connection.id}/info`}
                              component={RouterLink}
                              className={classes.listText}
                              color="textPrimary"
                              variant="h6"
                              noWrap
                            >
                              {connection.name}
                            </Typography>
                          }
                        />
                        {connection.active ? (
                          <StatusBullet color="success" size="small" />
                        ) : (
                          <Typography
                            className={classes.lastActivity}
                            variant="body2"
                          >
                            {moment(connection.lastActivity).fromNow()}
                          </Typography>
                        )}
                      </ListItem>
                    ))}
                </List>
              ))}
            </Box>
          </Box>
        </PerfectScrollbar>
      </Drawer>
    </>
  )
}

export default Users
