import cookie from 'js-cookie'

export class CookieService {
  setTokens = ({ accessToken, refreshToken }) => {
    cookie.set('accessToken', accessToken)
    cookie.set('refreshToken', refreshToken)
  }

  getAccessToken = () => {
    return cookie.get('accessToken')
  }

  getRefreshToken = () => {
    return cookie.get('refreshToken')
  }

  clearTokens = () => {
    cookie.remove('accessToken')
    cookie.remove('refreshToken')
  }
}

export default new CookieService()
