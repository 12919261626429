import axios from 'src/utils/axios'

class CommentsService {
  getList = (params = {}) => {
    return axios
      .get('/comments', { params: { ...params, sort: 'NEW' } })
      .then(res => res.data)
  }

  delete = id => axios.delete(`/comments/${id}`).then(res => res.data)
}

export default new CommentsService()
