import axios from 'axios'
import { cookieService } from 'src/services'

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV !== 'production' ? 'http://localhost:8000/api' : '/api'
})

instance.interceptors.request.use(
  config => {
    const token = cookieService.getAccessToken()
    if (token) config.headers['Authorization'] = `Bearer ${token}`
    return config
  },
  error => Promise.reject(error)
)

instance.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config

    if (
      error.response.status === 401 &&
      error.response.data.message !==
        'Чтобы продолжить войдите на сервис или зарегистрируйтесь'
    ) {
      const refreshToken = cookieService.getRefreshToken()
      return instance.post('/auth/tokens', { refreshToken }).then(res => {
        if (res.status === 201) {
          cookieService.setTokens(res.data)
          const token = cookieService.getAccessToken()
          instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
          return instance(originalRequest)
        }
      })
    }

    return Promise.reject(error)
  }
)

export default instance
